import PropTypes from "prop-types"
import React from "react"
import "./style.css"

function TipoBadge({ tipo, language }) {
  switch (tipo) {
    case "Protocolo":
      return (
        <div className="tr-badge bg-green justify-content-around">
          <span className="text-white">
            {language === "pt" ? "Protocolo" : "Protocol"}
          </span>
        </div>
      )

    case "Resultado":
      return (
        <div className="tr-badge bg-blue justify-content-around">
          <span className="text-white">
            {language === "pt" ? "Resultado" : "Result"}
          </span>
        </div>
      )

    default:
      return <></>
  }
}

TipoBadge.propTypes = {
  tipo: PropTypes.oneOf(["Protocolo", "Resultado"]).isRequired,
  language: PropTypes.oneOf(["pt", "en"]),
}

TipoBadge.defaultProps = {
  name: "Protocolo",
  language: "pt",
}

export default TipoBadge
