import PropTypes from "prop-types"
import React from "react"
import "./style.css"

function StatusBadge({ status, language }) {
  switch (status) {
    case "Publicação":
      return (
        <div className="tr-badge bg-yellow justify-content-around">
          <span className="text-white">
            {language === "pt" ? "Publicação" : "Publication"}
          </span>
        </div>
      )

    case "Pré-registro":
      return (
        <div className="tr-badge bg-grey-40 justify-content-around">
          <span className="text-white">
            {language === "pt" ? "Pré-registro" : "Preregistration"}
          </span>
        </div>
      )

    default:
      return <></>
  }
}

StatusBadge.propTypes = {
  status: PropTypes.oneOf(["Publicação", "Pré-registro"]).isRequired,
  language: PropTypes.oneOf(["pt", "en"]),
}

StatusBadge.defaultProps = {
  name: "Pré-registro",
  language: "pt",
}

export default StatusBadge
