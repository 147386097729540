import * as React from "react"
import GradientJumbo from "../components/gradient-jumbo"
import Layout from "../components/layout"
import Contact from "../components/sections/contact"
import ProjectCards from "../components/sections/portifolio-e-iniciativas/project_cards"
import Seo from "../components/seo"

const PortfolioPage = props => (
  <Layout language="pt" pathname={props.location.pathname}>
    <Seo
      title="Portfólio & Iniciativas"
      lang="pt"
      description="Acompanhe aqui os revisões sistemáticas e meta-análises por cientistas brasileiros"
    />
    <GradientJumbo
      subtitle="Portfólio & Iniciativas"
      title="Revisões sistemáticas e meta-análises por cientistas brasileiros"
    />
    <div id="plus"></div>
    <ProjectCards language="pt" />
    <Contact language="pt" />
  </Layout>
)

export default PortfolioPage
