import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { getMailTo, getProjectCitation } from "../../../utils"
import StatusBadge from "../../badges/status_badge"
import TipoBadge from "../../badges/tipo_badge"

const texts = {
  pt: ["Autores", "Citação", "Contato"],
  en: ["Authors", "Citation", "Contact"],
}

function ProjectCards({ language }) {
  const text = texts[language]

  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/(markdown/projects)/" } }
        ) {
          edges {
            node {
              id
              frontmatter {
                titulo
                titulo_en
                descricao
                descricao_en
                citacao
                autores
                contato
                link
                status
                tipo
              }
            }
          }
        }
      }
    `
  )

  return (
    <section className="container">
      <div className="row">
        {allMarkdownRemark.edges.map((a, i) => (
          <div className="col-lg-6 col-md-12 mb-4" key={i}>
            <div className="project-card">
              <div className="row">
                <div className="col-6">
                  <StatusBadge
                    status={a.node.frontmatter.status}
                    language={language}
                  />
                </div>
                <div className="col-6">
                  <TipoBadge
                    tipo={a.node.frontmatter.tipo}
                    language={language}
                  />
                </div>
              </div>
              {language === "pt" ? (
                <>
                  <h3 className="fw-bold mt-2">{a.node.frontmatter.titulo}</h3>
                  <h3 className="fw-normal">{a.node.frontmatter.titulo_en}</h3>
                  <p className="text-grey-20">{a.node.frontmatter.descricao}</p>
                </>
              ) : (
                <>
                  <h3 className="fw-bold mt-2">{a.node.frontmatter.titulo_en}</h3>
                  <p className="text-grey-20">{a.node.frontmatter.descricao_en}</p>
                </>
              )}
              <p className="text-grey-20">
                <b>{text[0]}</b>: {a.node.frontmatter.autores}
              </p>
              <p className="text-grey-20 font-monospace">
                <b>{text[1]}:</b> {a.node.frontmatter.citacao}.{" "} {getProjectCitation(language, a.node.frontmatter.link)}
              </p>
              <a
                href={getMailTo(a.node.frontmatter.contato)}
                className="text-decoration-none"
              >
                <p className="text-info">
                  <b>{text[2]}:</b> {a.node.frontmatter.contato}
                </p>
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

ProjectCards.propTypes = {
  language: PropTypes.oneOf(["pt", "en"]),
}

export default ProjectCards
